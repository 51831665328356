import Document from './Document';
import Media from './slices/Media';

class Prostaffer extends Document {
  constructor(prostaffer, siteUID, products) {
    super(prostaffer, siteUID);

    this.bio = prostaffer.bio;
    this.name = prostaffer.name;
    this.featuredMedia = new Media({
      type: prostaffer.featuredMedia?.type,
      media: prostaffer.featuredMedia,
    });
    this.gear = products;
    this.lat = prostaffer.location.lat;
    this.lng = prostaffer.location.lng;
    this.socialLinks =
      prostaffer.social?.map((link) => ({ type: link.type, url: link.url })) ||
      [];
    this.targetWaters = prostaffer.targetWaters;

    this.constructorName = 'Prostaffer';
  }
}

export default Prostaffer;
