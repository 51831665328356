import React, { Component } from 'react';
import { graphql } from 'gatsby';

import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import ProductModel from '../models/Product';
import ProstafferComponent from '../components/prostaff/prostaffer/Prostaffer';
import ProstafferModel from '../models/Prostaffer';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query ($prostafferId: String!, $productIds: [Int]!) {
    sanityProstaffer(_id: { eq: $prostafferId }) {
      _id
      bio {
        _rawLanguagePrimary
        _rawLanguageSecondary
      }
      featuredMedia {
        image {
          asset {
            gatsbyImageData
          }
        }
        type
        video {
          imageUrl
          value
        }
        videoPickit {
          imageUrl
          value
        }
        videoType
        videoYoutube
      }
      gear {
        uid {
          current
        }
        value
      }
      id
      location {
        lat
        lng
      }
      name
      social {
        type
        url
      }
      targetWaters
      uid {
        current
      }
    }
    allInRiverProduct(filter: { entityId: { in: $productIds } }) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

class Prostaffer extends Component {
  render() {
    const { brand, path, site } = this.props;
    const { siteUID } = this.props.pageContext;
    const { allInRiverProduct, sanityProstaffer } = this.props.data;

    const products = mapEdgesToNodes(allInRiverProduct).map(
      (product) => new ProductModel(product, siteUID, site)
    );
    const prostaffer = new ProstafferModel(sanityProstaffer, siteUID, products);

    return (
      <ProstafferComponent
        breadcrumbs={breadcrumbs('PROSTAFFER', prostaffer)}
        jsonLd={jsonLd(brand, site, path, prostaffer)}
        prostaffer={prostaffer}
        site={site}
      />
    );
  }
}

export default Prostaffer;
