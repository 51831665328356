import Helmet from 'react-helmet';
import React from 'react';

import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import IconLinks from '../../footer/IconLinks';
import JsonLD from '../../_common/JsonLD';
import Media from '../../slices/Media';
import Slice from '../../slices/Slice';

const Prostaffer = ({ breadcrumbs, jsonLd, prostaffer, site }) => {
  const igUrl =
    prostaffer.socialLinks.find((link) => link.type === 'Instagram')?.url ||
    null;

  return (
    <article className="page article prostaffer">
      <Helmet title={prostaffer.name} />
      <JsonLD jsonLd={jsonLd} />
      <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

      <header className="article__header">
        <div className="article__featured">
          <div className="article__featured-text">
            <h1 className="article__title">{prostaffer.name}</h1>
          </div>
        </div>

        <div className="article__featured-media">
          <Media media={prostaffer.featuredMedia} />
        </div>

        <IconLinks links={prostaffer.socialLinks} />
      </header>

      <Slice
        className="article__section"
        slice={{
          text: {
            languagePrimary: prostaffer.bio._rawLanguagePrimary,
            languageSecondary: prostaffer.bio._rawLanguageSecondary,
          },
          type: 'textSlice',
        }}
        site={site}
      />

      <Slice
        className="article__section"
        slice={{
          multiRow: true,
          products: prostaffer.gear,
          title: {
            languagePrimary: `${prostaffer.name}'s gear`,
            languageSecondary: `${prostaffer.name}'s gear`,
          },
          type: 'productsSlice',
        }}
        site={site}
      />

      {igUrl && (
        <section className="article__section">
          <h2>Latest instagram posts</h2>
          <div className="prostaffer__instagram-feed">
            <iframe src={`${igUrl}/embed/`} frameBorder="none" />
          </div>
        </section>
      )}
    </article>
  );
};

export default Prostaffer;
